import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';
import { Redirect } from 'react-router';
import Navbar from './container/NavBar';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { CmsDataWrapper } from 'context/cmsData';


const HomePage = React.lazy(() => import('./container/Home'));
const ContactPage = React.lazy(() => import('./container/Contact'));
// const RoomsPage = React.lazy(() => import('./container/Rooms'));
// const GroupContactForm = React.lazy(() => import('./component/ContactForm'));

function App({ cmsData }) {
  const { 'coredata': coreData } = cmsData;

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        <Navbar CoreData={coreData} />
          <Routes>
          <Route path="/contact-us" element={<ContactPage />} />
          <Route exact path="/" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/" />}></Route>
        </Routes>
        <Footer coreData={coreData} />
      </div>
    </Suspense>
  );
}

export default CmsDataWrapper(App, ['coredata']);
