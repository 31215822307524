import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { CmsDataProvider } from 'context/cmsData';
import AmpEmailAPI from 'api/AmpEmailAPI';

import './styles/tailwind_output.css';
import './index.css';
import './every-layout.css';

const container = document.getElementById('root');
const root = createRoot(container);

ReactGA.initialize(process.env.REACT_APP_GA_ID);

const pixelOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const pixelId = process.env.REACT_APP_FB_PIXEL;
ReactPixel.init(pixelId, {}, pixelOptions);

// ReactGA.set({ page: "/index" }); // Update the user's current page
// ReactGA.pageview("/index"); // Record a pageview for the given page 
//console.log(window.location.pathname);
ReactGA.ga('send', 'pageview', window.location.pathname);
ReactPixel.trackSingle(pixelId, "PageView");

// history.listen(location => {
//   AmpEmailAPI.trackPage(location.pathname.replace("/", ""));
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page 
//   ReactPixel.trackSingle(pixelId, "PageView");
// });

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
        <CmsDataProvider>
          <App />
        </CmsDataProvider>
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
