import React, { useState } from 'react';
import './style.css';
import { IoLogoFacebook, IoLogoInstagram, IoLogoPinterest } from 'react-icons/io';

const Footer = ({ coreData }) => {
    console.log("-", coreData);
    const SMIcons = { 'Facebook': <IoLogoFacebook />, 'Instagram': <IoLogoInstagram />, 'Pinterest': <IoLogoPinterest /> };
    const { AboutLinks, HelpLinks, SocialMediaLinks, Name, Email } = coreData.attributes;
    return <>
        <div className="relative p-4 md:p-8 lg:p-8 text-center text-white items-center z-0 bg-gray-900">
            <div className='text-3xl w-full'>{Name}</div>
            <div className='flex w-full'>
                <div className='w-1/3 text-left'>
                    <h3>About Us</h3>
                    {AboutLinks.map((link, i) => <div className='my-2' key={i}><a href={link.Url}>{link.Label}</a></div>)}
                </div>
                <div className='w-1/3 text-center'>
                    <div className='flex items-center justify-center'>
                        {SocialMediaLinks.map((link, i) => <div className=' mx-2 md:mx-4 lg:mx-8 my-2 text-4xl' key={i}><a href={link.Url}>{SMIcons[link.Label]}</a></div>)}
                    </div>
                    <div className='h-8 border-1 border-white w-[2px] mx-auto my-2'></div>
                    <div className='text-3xl'>Get In Touch</div>
                    <div className='text-xl my-8'>{Email}</div>
                </div>
                <div className='w-1/3 text-right'>
                    <h3>Help</h3>
                    {HelpLinks.map((link, i) => <div className='my-2' key={i}><a href={link.Url}>{link.Label}</a></div>)}
                </div>
            </div>
            <div className='text-md mt-8'>© {new Date().getFullYear()} {Name}. All Rights Reserved </div>
        </div>
    </>;
};

export default Footer;
