import React, { useState, useEffect, createContext, useContext } from "react";
import Loader from 'component/Loader';

export const CmsDataContext = createContext();


function CmsDataProvider(props) {
  const [cmsData, setCmsData] = useState({});

  const getRequiredCmsData = async (types) => {
    
    const typesWithRequiredTypes = [...types, 'coredata'];
    let unfetchedTypes = typesWithRequiredTypes.filter(type => cmsData[type] === undefined);
    unfetchedTypes = [...new Set(unfetchedTypes)];
    if (unfetchedTypes.length === 0) return;
    let promises = [];
    for(var i=0;i<unfetchedTypes.length;i++){
      let promise = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/${unfetchedTypes[i]}?populate=deep,5`)
      .then(resp => resp.json())
      .then((resp)=>{
        let returnData= {};
        returnData[unfetchedTypes[i]] = resp;
        return  returnData;
      });
      promises.push(promise)
    }
    Promise.all([
      promises
    ]).then(result=>{
      const updatedData = { ...cmsData }
      for(var i=0;i<result[0].length;i++){
        for (const key in result[0][i]) {
          updatedData[key] = result[0][i][key].data;
        }
      }
      setCmsData(updatedData);
    }).catch(error =>{
       console.log('Something went wrong');
    });
  }

  const value = { cmsData, getRequiredCmsData };

  return <CmsDataContext.Provider value={value} {...props} />;
}

function useCmsDataContext() {
  return useContext(CmsDataContext);
}


/**
 * CmsDataWrapper which wraps top level page components and delegates 
 */
const CmsDataWrapper = (Component, requiredTypes) => function Comp(props) {
  const { cmsData, getRequiredCmsData } = useCmsDataContext();
  getRequiredCmsData(requiredTypes);

  let hasAllRequiredData = true;

  for (const type of requiredTypes) {
    hasAllRequiredData = hasAllRequiredData && !!cmsData[type];
    if (!hasAllRequiredData) break;
  }

  if (!hasAllRequiredData) {
    return <Loader></Loader>
  } else {
    return <Component cmsData={cmsData} {...props}></Component>
  }
}

export { CmsDataProvider, useCmsDataContext, CmsDataWrapper };
